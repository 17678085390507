.viewimage{
    object-fit: fill;
    width: 100%;
    height: 400px;
}

.carousel-control-prev {
    width: 4%;
}

.carousel-control-next {
    width: 4%;
}

.visually-hidden {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
  
  .ssssss {
    width: 800px !important;
  }