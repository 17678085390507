.table > :not(caption) > * > * {
  vertical-align: middle !important;
  padding: 0.1rem 0.2rem !important;
}

.result {
  border-color: #2a2b2c !important;
}

table {
  width: 100%;

  overflow: scroll;
  max-width: 95vw;
}

@media print {
  table th {
    font-size: 0.8rem;
    font-weight: 600;
  }
  table td {
    font-size: 0.8rem;
  }
}
